import { useState, useEffect } from 'react';

const Delay = ({ by = 1000, children }) => {
    const [isTimeoutDone, setIsTimeoutDone] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setIsTimeoutDone(true), by);

        return () => clearTimeout(timer);
    }, [by]);

    return isTimeoutDone ? children : null;
};

export default Delay;
