import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { MembershipType, MembershipStatus } from 'api/types';
import { newUserAttributionEventOwner } from 'pages/search/helpers';
import { locationName } from 'api/helpers/format/location';
import { userCameFromSearch, userOnEntryPage } from 'utils/integrations/sbjs';
import RegwallDialog, { OpenMethod } from 'components/RegwallDialog';
import Delay from 'components/Delay';
import ProfileDetailPage from 'components/ProfileDetailPage/ProfileDetailPage';
import { useRegwall, RegwallContext } from '../useRegwall';

const ProfileDetail = ({
    t,
    profile,
    membershipType,
    membershipStatus,
    isLoggedIn,
    hasApprovedListing,
    ownerListingId,
    defaultMonth,
    seoContent,
    router,
    profileHasLoaded,
    showSittersLookingInYourAreaTestFeatures,
    isFavourited,
    reloadOwnerFavourites,
}) => {
    const [isRegwallOpen, openRegwall, closeRegwall] = useRegwall({ isLoggedIn, router });
    const isRegwallClosable = userCameFromSearch() && userOnEntryPage();
    const hasPartner = profile && profile.partner && profile.partner.firstName;
    const profileName = hasPartner
        ? `${profile.sitterName} & ${profile.partner.firstName}`
        : profile.sitterName;

    useEffect(() => {
        if (!isLoggedIn) {
            newUserAttributionEventOwner();
        }
        if (isLoggedIn && isRegwallOpen) {
            closeRegwall();
        }
    }, [closeRegwall, isLoggedIn, isRegwallOpen]);

    const getDescription = () => {
        const city = locationName(profile.location);

        let metaDescription = t('pages_profileDetail_metaDescription', {
            count: hasPartner ? 2 : 1,
            name: profileName,
            location: city,
            quote: profile.title,
        });

        if (metaDescription.length < 100) {
            metaDescription = t('pages_profileDetail_metaDescription_alt', {
                name: profileName,
                city,
                country:
                    typeof profile.location === 'object' &&
                    Object.prototype.hasOwnProperty.call(profile.location, 'countryName')
                        ? profile.location.countryName
                        : '',
            });
        }

        return metaDescription;
    };

    return (
        <RegwallContext.Provider
            value={{
                isOpen: isRegwallOpen,
                open: openRegwall,
                close: closeRegwall,
            }}
        >
            <Helmet>
                {!seoContent.hasTitle && (
                    <title>
                        {t('pages_profileDetail_title', {
                            count: hasPartner ? 2 : 1,
                            name: profileName,
                            location: locationName(profile.location, true),
                        })}
                    </title>
                )}
                <meta name="robots" content="noindex" />
                {!seoContent.hasDescription && (
                    <meta name="description" content={getDescription()} />
                )}
                <link
                    href="https://api.mapbox.com/mapbox-gl-js/v1.13.2/mapbox-gl.css"
                    rel="stylesheet"
                />
            </Helmet>

            <Delay>
                <RegwallDialog
                    openMethod={OpenMethod.TIME_DELAY}
                    membershipType={MembershipType.OWNER}
                    isOpen={isRegwallOpen}
                    onCloseHandler={isRegwallClosable && closeRegwall}
                    t={t}
                />
            </Delay>

            <ProfileDetailPage
                t={t}
                profile={profile}
                membershipType={membershipType}
                membershipStatus={membershipStatus}
                isLoggedIn={isLoggedIn}
                hasApprovedListing={hasApprovedListing}
                ownerListingId={ownerListingId}
                defaultMonth={defaultMonth}
                profileHasLoaded={profileHasLoaded}
                showSittersLookingInYourAreaTestFeatures={showSittersLookingInYourAreaTestFeatures}
                isFavourited={isFavourited}
                reloadOwnerFavourites={reloadOwnerFavourites}
            />
        </RegwallContext.Provider>
    );
    /* eslint-enable */
};

export default ProfileDetail;
